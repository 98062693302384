<template>
    <div class="pb-1 pt-14">
        <!-- <div class="container mx-auto md:w-2/3"> -->
        <!-- slider -->
        <div class="grid grid-cols-1 text-sm">
            <Slider />
        </div>
        <!-- </div> -->
        <div class="container mx-auto grid grid-cols-1 py-3 sm:w-full md:w-4/12">
            <SliderCampaigns 
                :campaign1Title="campaign1Title" 
                :campaign1Id="campaign1Id"
                :campaign1="campaign1"
            />
            <div class="px-3">
                <!-- categoryHome -->
                <CategoryHome :parameter="settingKategori.deskripsi"/>
            </div>

            <div class="mt-5"></div>

            <div v-if="campaign1.length > 0" class="mt-6 mb-3">
                <router-link :to="{name: 'campaign.show', params:{slug: bannerImageSlug}}">
                    <img :src="bannerImage" />
                </router-link>
            </div>

            <div v-else>
                <div class="grid grid-cols-1 bg-white rounded shadow-md p-3 text-sm mt-4 mb-4">
                    <FacebookLoader class="h-24" />
                </div>
            </div>

            <div v-if="campaign2.length > 0">
                <div class="flex flex-row justify-between">
                    <h1 class="mt-5 pl-3 flex-auto text-xl font-semibold">
                        {{campaign2Title}}
                    </h1>
                    <div v-if="campaign2.length >= 5">
                        <router-link :to="{name: 'campaign.program.index', params:{id: campaign2Id}}">
                            <h1 class="mt-6 pr-3 text-base underline font-semibold text-yellow-500">
                                Lihat Semua
                            </h1>
                        </router-link>
                    </div>
                </div>

                <div class="px-1 flex overflow-x-auto">
                    <div v-for="(campaign) in campaign2" :key="campaign.id">
                        <CampaignItem :image="campaign.image" :title="campaign.title" :slug="campaign.slug"
                            :sumDonation="campaign.sum_donation" :targetDonation="campaign.target_donation"
                            :maxDate="campaign.max_date" />
                    </div>
                </div>
            </div>
            <div v-else>
                <div v-for="index in 2" :key="index"
                    class="grid grid-cols-1 bg-white rounded shadow-md p-3 text-sm mt-4 mb-4">
                    <FacebookLoader class="h-24" />
                </div>
            </div>
            <div class="mt-6 mb-3">
                <div class="flex flex-row justify-between">
                    <h1 class="mt-5 pl-3 flex-auto text-xl font-semibold">
                        E-Book
                    </h1>
                    <div v-if="campaign2.length >= 5">
                        <a href="https://domyadhu.org/ebook/index.php" target="_blank">
                            <h1 class="mt-6 pr-3 text-base underline font-semibold text-yellow-500">
                                Lihat Semua
                            </h1>
                        </a>
                    </div>
                </div>
                <div class="relative ebook">
                    <button @click="scrollLeft" 
                    class="absolute left-0 top-1/2 -translate-y-1/2 bg-yellow-500 text-white px-2 py-1 rounded-full z-10">
                        &lt;
                    </button>
                    <div ref="slider" class="px-1 flex overflow-x-auto scroll-smooth">
                        <div v-for="(ebooks) in ebook" :key="ebooks.id">
                            <Ebook :image="ebooks.image" :title="ebooks.deskripsi"
                                :pdf="ebooks.pdf" :dateEbook="ebooks.created_at"
                            />
                        </div>
                    </div>
                    <button @click="scrollRight" 
                    class="absolute right-0 top-1/2 -translate-y-1/2 bg-yellow-500 text-white px-2 py-1 rounded-full z-10">
                        &gt;
                    </button>
                </div>
                <!-- <Ebook 
                    :data="ebook"
                /> -->
            </div>
            <h1 class="mt-5 mb-0 pl-3 flex-auto text-xl font-semibold">
                Realisasi Program
            </h1>
            <div class="bg-white rounded-xl shadow-md my-4 mx-2">
                <div class="rounded-xl md:p-0">
                    <img class="h-auto w-full rounded-tl-xl rounded-tr-xl object-cover" :src="realImage">
                    <div class="w-full pt-2 md:pt-5 p-4 md:p-3 text-left space-y-2">
                        <p class="text-sm font-semibold title">{{ realTitle }}</p>
                        <p class="text-xs font-normal">{{ realDesc }}</p>
                    </div>
                </div>
            </div>

            <h1 class="mt-5 mb-0 pl-3 flex-auto text-xl font-semibold">
                Dari sahabat baik untuk mereka
            </h1>
            <div class="rounded-xl shadow-md my-4 mx-2">
                <div class="rounded-xl md:p-0">
                    <iframe class="w-full h-80 rounded-xl" :src="youtube" frameborder="0"></iframe>
                </div>
            </div>

            <div class="bg-yellow-500 w-full p-3 mt-5">
                <p class="m-auto text-sm text-white font-semibold text-center">
                    Dompet Yatim dan Dhuafa
                </p>
                <div class="w-24 bg-white mx-auto my-3" style="height: 1px">&nbsp;</div>
                <p class="m-auto text-sm text-white font-semibold text-center px-10 pb-5">
                    Donasi online Dompet Yatim dan Dhuafa adalah layanan Zakat, infaq dan sedekah berbasis online milik
                    Dompet Yatim dan Dhuafa yang merupakan lembaga amil zakat yang berfokus pada pendidikan dan
                    pendayagunaan yatim dan dhuafa.
                </p>
                <a href="https://www.domyadhu.org/" target="_blank" rel="noopener noreferrer">
                    <div class="text-sm text-white text-center font-medium pb-5 underline">
                        Tentang kami
                    </div>
                </a>
                <div class="text-sm text-white font-normal pb-5">
                    Sekretariat
                    <br>
                    <p class="font-semibold">
                        Jl. Raya Muchtar No.78, RW.3, Sawangan Baru, Kec. Sawangan, Kota Depok, Jawa Barat 16511
                    </p>
                </div>
                <div class="text-sm text-white font-normal pb-5">
                    Telp: (0251) 8606 740
                    <br>
                    WA: +62 8551100028 <a href="https://wa.me/628551100028?text=ini%20adalah%20pesan%20saya">(Kirim Whatsapp)</a>
                    <br>
                    Email: support@domyadhu.org
                </div>
                <div class="text-sm text-white font-normal">
                    Follow Us:
                </div>
                <div class="mb-10 flex pb-3">
                    <a href="https://www.facebook.com/dompetdonasi.id" target="_blank" rel="noopener noreferrer">
                        <img width="30" height="30" src="@/assets/images/facebook-white.png" />
                    </a>
                    &nbsp;
                    <a href="https://www.instagram.com/domyadhu.official/" target="_blank" rel="noopener noreferrer">
                        <img width="30" height="30" src="@/assets/images/instagram-white.png" />
                    </a>
                    &nbsp;
                    <a href="https://www.youtube.com/channel/UCfp-LXAj5d2xQBtvIaWeLhQ" target="_blank"
                        rel="noopener noreferrer">
                        <img width="30" height="30" src="@/assets/images/youtube-white.png" />
                    </a>
                </div>
            </div>

        </div>
        <!-- <Popup :image="popupImage" :link="popupLink" :isNotLoading="campaign1.length > 0" /> -->

    </div>
</template>

<script>

    //hook vue
    import { ref, computed, onMounted } from 'vue'

    //vuex
    import { useStore } from 'vuex'

    //component slider
    import Slider from '@/components/Slider.vue'

    //component categoryHome
    import CategoryHome from '@/components/CategoryHome.vue'

    //component categoryHome
    import CampaignItem from '@/components/CampaignItem.vue'
    // import TestItem from '@/components/TestItem.vue'
    //import Popup from '@/components/home/Popup.vue'
    import SliderCampaigns from '@/components/home/SliderCampaigns.vue'
    import Ebook from '@/components/home/Ebook.vue'

    //vue content loader
    import { FacebookLoader } from 'vue-content-loader'
    
    // import { VueperSlides, VueperSlide } from 'vueperslides'
    // import 'vueperslides/dist/vueperslides.css'
    // import 'vue3-carousel/dist/carousel.css'
    // import { Carousel, Slide } from 'vue3-carousel'

    export default {

        components: {
            Slider,         // <-- register component slider
            CategoryHome,   // <-- register component CategoryHome
            FacebookLoader,  // <-- register component FacebooLoader dari Vue Content Loader
            CampaignItem,
            //Popup,
            SliderCampaigns,
            Ebook,
            // VueperSlides, 
            // VueperSlide
            // Carousel,
            // Slide
        },

        computed: {
            filteredItems: function () {
                return this.campaign1.slice(0, 2)
            },
        },

        setup() {

            //store vuex
            const store = useStore()
            const slider = ref(null);

            // Fungsi untuk menggeser slider ke kiri
            const scrollLeft = () => {
                slider.value.scrollBy({ left: -300, behavior: 'smooth' });
            };

            // Fungsi untuk menggeser slider ke kanan
            const scrollRight = () => {
                slider.value.scrollBy({ left: 300, behavior: 'smooth' });
            };

            //onMounted akan menjalankan action "getCampaign" di module "campaign"
            onMounted(() => {
                document.title = `Dompet Donasi`;
                store.dispatch('home/getHomeData');
            })
            //digunakan untuk get data  state "campaign1Title" di module "campaign" 
            const campaign1Id = computed(() => {
                console.log('id ', store.state.home.campaignProgram1Id)
                return store.state.home.campaignProgram1Id
            })
            const campaign1Title = computed(() => {
                return store.state.home.campaignProgram1Title
            })
            const campaign1 = computed(() => {
                // console.log('prog1 ', store.state.home.campaignProgram1)
                return store.state.home.campaignProgram1
            })
            const ebook = computed(() => {
                return store.state.home.ebook
            })
            const settingKategori = computed(() => {
                return store.state.home.settingKategori
            })
            const campaign2Id = computed(() => {
                return store.state.home.campaignProgram2Id
            })
            const campaign2Title = computed(() => {
                return store.state.home.campaignProgram2Title
            })
            const campaign2 = computed(() => {
                return store.state.home.campaignProgram2
            })
            const bannerImage = computed(() => {
                return store.state.home.bannerImage
            })
            const bannerImageSlug = computed(() => {
                console.log('muncul => ', store.state.home.bannerImageSlug)
                return store.state.home.bannerImageSlug
            })
            const realTitle = computed(() => {
                return store.state.home.realTitle
            })
            const realImage = computed(() => {
                return store.state.home.realImage
            })
            const realDesc = computed(() => {
                return store.state.home.realDesc
            })
            const popupImage = computed(() => {
                return store.state.home.popupImage
            })
            const popupLink = computed(() => {
                return store.state.home.popupLink
            })
            const youtube = computed(() => {
                return "https://www.youtube.com/embed/" + store.state.home.linkYoutube
            })

            /**
             * LOADMORE
             */

            //get status NextExists
            const nextExists = computed(() => {
                return store.state.campaign.nextExists
            })

            //get nextPage
            const nextPage = computed(() => {
                return store.state.campaign.nextPage
            })

            //loadMore function
            function loadMore() {
                store.dispatch('campaign/getLoadMore', nextPage.value)
            }

            return {
                campaign1Id,
                campaign1Title,
                ebook,
                settingKategori,
                campaign1,
                campaign2Id,
                campaign2Title,
                campaign2,
                bannerImage,
                bannerImageSlug,
                realTitle,
                realImage,
                realDesc,
                popupImage,
                popupLink,
                youtube,
                nextExists,     // <-- return nextExists,
                nextPage,       // <-- return nextPage
                loadMore,        // <-- return loadMore
                slider,
                scrollLeft,
                scrollRight,
            }

        }

    }
</script>
<style scoped>
/* .small-slide {
    width: 80% !important;
    margin: 0 auto !important;
    padding: 10px !important;
    box-sizing: border-box !important;
} */
/* .custom-carousel {
    max-width: 90% !important;
    margin: 0 auto !important;
} */
/* .vueperslides__bullet .default {
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
  box-shadow: none;
  transition: 0.3s;
  width: 16px;
  height: 16px;
}

.vueperslides__bullet--active .default {background-color: #42b983;}

.vueperslides__bullet span {
  display: block;
  color: #fff;
  font-size: 10px;
  opacity: 0.8;
} */
.ebook button {
    cursor: pointer;
}
</style>